body {
  width: 100vw;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: none;
  background-color: rgb(0, 160, 230);
  color: white;
  padding: 10px;
  margin: 5px;
  font-weight: bold;
}

.stack-of-things {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.gift-option-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.in-stock-gift {
  height: 150px;
  box-shadow: 3px 3px 3px lightgrey;
  margin-right: 20px;
}

.out-of-stock-gift {
  height: 150px;
  box-shadow: 3px 3px 3px lightgrey;
  filter: opacity(10%);
  margin-right: 20px;
}

.small-text {
  font-size: small;
}

.dialog-background {
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #77777777;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dialog {
  z-index: 2;
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  max-width: 70%;
}

.gift-full-screen {
  width: 100%;
}

.tree-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selected-decoration {
  border-style: solid;
  border-width: 2;
  border-color: red;
}

.unselected-decoration {
  border-style: solid;
  border-width: 2;
  border-color: transparent;
}